import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, Button, Select, Dropdown, Menu, Icon, DatePicker, Modal } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { selectUser, selectUserPemissions, selectUserEmail, roleList, rmeVendors } from './../selectors/UserSelector';
import { PERMISSIONS } from '../lib/Constants';
import {
  getValueBasedOnCondition,
  checkConditionWithOr,
  checkConditionWithAnd,
  checkConditionWithThreeOr
} from '../lib/Helpers';

let { Option } = Select;
const PHONE_TYPES = ['Work', 'Mobile', 'Home', 'Primary'];

const {
  CNTAPP: {
    CONTACT: { UPDATE, DELETE, SELF_UPDATE, SELF_DELETE, OTHER_UPDATE, OTHER_DELETE, LINK }
  }
} = PERMISSIONS;

function checkDisableStatusForPrimary(id, props) {
  let disableStatus = false;
  if (id === 1) {
    disableStatus = true;
    props.missingRoles.forEach(roleId => {
      if (roleId === 1) disableStatus = false;
    });
  }
  return disableStatus;
}

function checkForSkippingPhoneValidation(cardType, props, phoneObj) {
  if (cardType === 'UPDATE') {
    const existingPhones = _.get(props.contact, 'phone', []);
    const index = _.findIndex(existingPhones, o => phoneObj.phoneId === o.phoneId);

    if (index !== -1) {
      if (existingPhones[index].number === phoneObj.number) return true;
    }
  }
}

function checkEmailValidity(regex, email, confirmationEmail) {
  if (!regex.test(String(email).toLowerCase())) {
    return { validEmail: false, validationCode: 1, validationMessage: 'Invalid email address' };
  } else if (email !== confirmationEmail) {
    return { validEmail: false, validationCode: 2, validationMessage: 'Email and confirmation email mismatch' };
  }

  return { validEmail: true };
}

function getRelavantButtons(
  cardType,
  onSave,
  onClickEdit,
  onUpdate,
  onClickDeleteContact,
  onCancel,
  updatePermission,
  deletePermission
) {
  return (
    <div>
      {cardType === 'NEW' && (
        <Button className="action-btn save-btn" onClick={onSave}>
          Save
        </Button>
      )}
      {cardType === 'DETAILS' && updatePermission && (
        <Button className="action-btn" onClick={onClickEdit}>
          Edit
        </Button>
      )}
      {cardType === 'UPDATE' && (
        <Button className="action-btn save-btn" onClick={onUpdate}>
          Save
        </Button>
      )}
      {cardType === 'UPDATE' && deletePermission && (
        <Button className="action-btn delete-btn" onClick={onClickDeleteContact}>
          Delete
        </Button>
      )}
      {cardType === 'UPDATE' && (
        <Button className="action-btn cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </div>
  );
}

function getValidDate(date) {
  let epoch = 0;
  if (date) {
    epoch = moment(date, 'MM/DD/YYYY').valueOf();
  }
  return epoch;
}

function displayValidationErrors(condition, errorMessage) {
  return condition && <div className="validation-msg">{errorMessage}</div>;
}

function checkPermissionsBasedOnEmail(
  userEmail,
  email,
  currentUpdatePermission,
  currentDeletePermission,
  userPermissions
) {
  let updatePermission = currentUpdatePermission;
  let deletePermission = currentDeletePermission;
  if (userEmail === email) {
    updatePermission = _.includes(userPermissions, SELF_UPDATE);
    deletePermission = _.includes(userPermissions, SELF_DELETE);
  } else {
    updatePermission = _.includes(userPermissions, OTHER_UPDATE);
    deletePermission = _.includes(userPermissions, OTHER_DELETE);
  }
  return [updatePermission, deletePermission];
}

export default function ContactDetailsCard(props) {
  const [cardType, setCardType] = useState('DETAILS'); // cardType = NEW | UPDATE | DETAILS
  const [contactId, setcontactId] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [roles, setRoles] = useState([]);
  const [futureRoles, setFutureRoles] = useState([]);
  const [email, setEmail] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [phones, setPhones] = useState([{ phoneId: new Date().valueOf(), number: '', type: PHONE_TYPES[0] }]);
  const [validTill, setValidTill] = useState(0);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const ROLES = useSelector(roleList);
  const rmeVendorNumbers = useSelector(rmeVendors);

  const { suvc, onClose, getProfileImageText, createContact, updateContact, deleteContact } = props;
  let initials = getProfileImageText(name);
  
  const userData = useSelector(selectUser);
  const userPermissions = useSelector(selectUserPemissions);
  const userEmail = useSelector(selectUserEmail);
  const assignRolePermission = _.includes(userPermissions, LINK);

  let updatePermission = _.includes(userPermissions, UPDATE);
  let deletePermission = _.includes(userPermissions, DELETE);

  const [checkedUpdatePermission, checkedDeletePermission] = checkPermissionsBasedOnEmail(
    userEmail,
    email,
    updatePermission,
    deletePermission,
    userPermissions
  );
  updatePermission = checkedUpdatePermission;
  deletePermission = checkedDeletePermission;

  useEffect(() => {
    setCardType(props.cardType);

    if (!_.isEmpty(props.contact)) {
      assignDefaultValues();
    }
  }, [props.contact, props.cardType]);

  const assignDefaultValues = () => {
    setcontactId(props.contact.contactId);
    setName(props.contact.name);
    setTitle(props.contact.title);
    setRoles(props.contact.roles);
    setEmail(props.contact.email);
    setPhones(props.contact.phone);
    setValidTill(props.contact.validTill);
    setFutureRoles(props.contact.futureRoles);
  };

  const missingRolesAlert = () => {
    if (cardType === 'NEW') {
      let missingRoles = props.missingRoles;
      let alert = 'Required contacts missing:';
      let showAlert = false;

      missingRoles.forEach(roleId => {
        if (checkConditionWithThreeOr(roleId === 1, roleId === 2, roleId === 4)) {
          showAlert = true;
          let roleObj = _.find(ROLES, o => roleId === o.id);
          let roleName = _.get(roleObj, 'name', '');
          alert += ` ${roleName},`;
        }
      });

      alert = alert.slice(0, -1);
      if (showAlert) {
        return <div className="missing-contacts-alert">{alert}</div>;
      }
    }

    return <div />;
  };

  const disablePrimaryRoleSelection = id => {
    return checkDisableStatusForPrimary(id, props);
  };

  const sortByRolePriority = data => {
    let ordering = {};
    let sortOrder = [1, 2, 4, 3, 5, 6, 7, 8, 9, 10, 11, 12, 14];

    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }

    let sorted = data.sort((a, b) => {
      return ordering[a] - ordering[b];
    });

    return sorted;
  };

  // Fix for the invalid phone issue
  const skipPhoneValidation = phoneObj => {
    return checkForSkippingPhoneValidation(cardType, props, phoneObj);
  };

  const isAllDataValid = () => {
    let isAllPhonesValid = true;
    let { validEmail } = isEmailValid();

    _.forEach(phones, obj => {
      if (!isPhoneValid(obj)) {
        isAllPhonesValid = false;
      }
    });

    if (cardType === 'UPDATE') validEmail = true;

    if (isNameValid() && isTitleValid() && validEmail && isAllPhonesValid && isRoleslValid() && isFutureRolesValid()) {
      return true;
    }
  };

  const isFutureRolesValid = () => {
    let valid = true;
    futureRoles.forEach(futureRole => {
      if (futureRole.validFrom && futureRole.validUntil && futureRole.validUntil < futureRole.validFrom) {
        valid = false;
      }
    });
    return valid;
  };

  const isNameValid = () => {
    return getValueBasedOnCondition(!_.isEmpty(name), true, false);
  };

  const isTitleValid = () => {
    return getValueBasedOnCondition(!_.isEmpty(title), true, false);
  };

  const isEmailValid = () => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return checkEmailValidity(regex, email, confirmationEmail);
  };

  const isPhoneValid = phoneObj => {
    if (skipPhoneValidation(phoneObj)) return true;
    if (checkConditionWithAnd(!_.isEmpty(phoneObj.number), isPossiblePhoneNumber(phoneObj.number))) return true;
  };

  const isRoleslValid = () => {
    // return !_.isEmpty(roles) ? true : false;
    return true; // TODO: Since roles can be unassigned
  };

  const onSave = () => {
    let contact = {
      contactId: new Date().valueOf(),
      name,
      title,
      roles,
      email,
      phone: phones,
      validTill,
      suvc,
      username: email,
      futureRoles
    };

    console.log('contact', contact);

    if (isAllDataValid()) {
      createContact(suvc, contact);
      onClose();
    } else {
      setShowValidationMessage(true);
    }
  };

  const onUpdate = () => {
    let contact = {
      name,
      title,
      roles,
      email,
      phone: phones,
      validTill,
      futureRoles
    };

    if (isAllDataValid()) {
      updateContact(suvc, contactId, contact);
      setCardType('DETAILS');
    } else {
      setShowValidationMessage(true);
    }
  };

  const onClickEdit = () => {
    setCardType('UPDATE');
  };

  const onClickDeleteContact = () => {
    setShowConfirmationModal(true);
  };

  const onConfirmDelete = () => {
    deleteContact(suvc, contactId);
    onClose();
  };

  const onClickRetain = () => {
    if (!_.isEmpty(roles)) {
      let contact = {
        name,
        title,
        roles: [],
        email,
        phone: phones,
        validTill
      };

      updateContact(suvc, contactId, contact);
    }

    setShowConfirmationModal(false);
  };

  const onCancel = () => {
    setCardType('DETAILS');
    assignDefaultValues();
  };

  const onChangeName = e => {
    setName(e.target.value);
  };

  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onChangeConfirmationEmail = e => {
    setConfirmationEmail(e.target.value);
  };

  const onChangePhone = (phoneId, value) => {
    const index = _.findIndex(phones, o => {
      return phoneId === o.phoneId;
    });
    if (index !== -1) {
      let phonesClone = [...phones];
      let newPhone = { ...phones[index], ...value };
      phonesClone[index] = newPhone;
      setPhones(phonesClone);
    }
  };

  const onAddPhone = () => {
    let phonesClone = [...phones];
    phonesClone.push({ phoneId: new Date().valueOf(), number: '', type: PHONE_TYPES[0] });
    setPhones(phonesClone);
  };

  const onDeletePhone = phoneId => {
    let remainingPhones = _.filter(phones, o => {
      return phoneId !== o.phoneId;
    });
    setPhones(remainingPhones);
  };

  const onAddRole = role => {
    const index = _.findIndex(roles, o => {
      return role.id === o;
    });

    if (index === -1) {
      let clone = [...roles];
      clone.push(role.id);

      let sortedRoles = sortByRolePriority(clone);

      setRoles(sortedRoles);
    }
  };

  const onDeleteRole = roleId => {
    let remainingRoles = _.filter(roles, id => {
      return roleId !== id;
    });
    setRoles(remainingRoles);
  };

  const onChangeDate = (date, dateString) => {
    const epoch = getValidDate(date);
    setValidTill(epoch);
  };

  const renderTitleSection = () => {
    if (checkConditionWithOr(cardType === 'NEW', cardType === 'UPDATE')) {
      let invalidName = checkConditionWithAnd(!isNameValid(), showValidationMessage);
      let invalidTitle = checkConditionWithAnd(!isTitleValid(), showValidationMessage);

      return (
        <div className="title-section top-title-section">
          <div className="input-details-name">
            <Input
              className={getValueBasedOnCondition(invalidName, 'invalid-field', '')}
              placeholder="Name"
              value={name}
              onChange={onChangeName}
            />
            {displayValidationErrors(invalidName, 'Name cannot be empty')}
          </div>
          <div className="input-contact-title">
            <Input
              className={getValueBasedOnCondition(invalidTitle, 'invalid-field', '')}
              placeholder="Title"
              value={title}
              onChange={onChangeTitle}
            />
            {displayValidationErrors(invalidTitle, 'Title cannot be empty')}
          </div>
        </div>
      );
    }
    return (
      <div className="title-section top-title-section">
        <div className="details-name">{name}</div>
        <div className="details-contact-title">{title}</div>
      </div>
    );
  };

  const renderContactSection = () => {
    let { validEmail, validationMessage, validationCode } = isEmailValid();
    if (checkConditionWithOr(cardType === 'NEW', cardType === 'UPDATE')) {
      return (
        <React.Fragment>
          {cardType === 'NEW' ? (
            <React.Fragment>
              <div className="cd-field-name">Email</div>
              <div className="cd-info-msg">A new user is created with the email provided</div>

              <Input
                className={`input-email ${getValueBasedOnCondition(
                  checkConditionWithAnd(validationCode === 1, showValidationMessage),
                  'invalid-field',
                  ''
                )}`}
                placeholder="Email Address"
                value={email}
                onChange={onChangeEmail}
              />

              <Input
                className={`input-confirm-email ${getValueBasedOnCondition(
                  checkConditionWithAnd(validationCode === 2, showValidationMessage),
                  'invalid-field',
                  ''
                )}`}
                placeholder="Confirm Email Address"
                value={confirmationEmail}
                onChange={onChangeConfirmationEmail}
              />

              {!validEmail && showValidationMessage && <div className="validation-msg">{validationMessage}</div>}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="cd-field-name">Email</div>
              <div className="cd-info-msg">Email cannot be changed</div>
              <div className="cd-email">{email}</div>
            </React.Fragment>
          )}

          <div className="phones-section">
            <div className="cd-field-name">Phones</div>
            {phones.map((obj, index) => {
              let validPhone = checkConditionWithAnd(!isPhoneValid(obj), showValidationMessage);

              return (
                <div key={obj.phoneId}>
                  <div className="update-phone-wrapper">
                    <PhoneInput
                      size="small"
                      placeholder="Number"
                      className={`phone-input-textbox ${getValueBasedOnCondition(validPhone, 'invalid-field', '')}`}
                      defaultCountry="US"
                      value={obj.number}
                      onChange={value => onChangePhone(obj.phoneId, { number: value })}
                    />

                    <Select defaultValue={obj.type} onChange={value => onChangePhone(obj.phoneId, { type: value })}>
                      {PHONE_TYPES.map(opt => {
                        return (
                          <Option value={opt} key={opt}>
                            {opt}
                          </Option>
                        );
                      })}
                    </Select>
                    {phones.length !== 1 && (
                      <div className="btn-close-details" onClick={() => onDeletePhone(obj.phoneId)}>
                        x
                      </div>
                    )}
                  </div>
                  {displayValidationErrors(validPhone, 'Invalid phone number')}
                </div>
              );
            })}
          </div>

          <div className="btn-add-phone">
            <Button onClick={onAddPhone}>Add Phone</Button>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="cd-field-name">Email</div>
          <div className="cd-email">{email}</div>
          <div className="cd-field-name">Phones</div>
          <div className="phones-section">
            {phones.map(obj => {
              return (
                <div key={obj.phoneId} className="phones-wrapper">
                  <div className="phone-number">{obj.number}</div>
                  <div className="phone-option">{obj.type}</div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      );
    }
  };

  const isCollegue = () => {
    let editUserRoles = _.get(userData, 'roles', []);
    let isCollegue = editUserRoles.find(x => x >=500 && x < 2000);
    return !!isCollegue;
  };

  const getRoleSpecificEditPermission = (role) => {
    let roleAdmins = _.get(role, 'roleAdmins', []);
    let modifiableRolesOverrides = _.get(userData, 'modifiableRolesOverride', []);
    let editUserRoles = _.get(userData, 'roles', []);
    let adminRolesList = [1, 2, 4];

    if (roleAdmins.length > 0) {
      return _.intersection(editUserRoles, roleAdmins).length > 0;
    } else {
      let isEditableBySupplier = _.get(role, 'isEditableBySupplier', false);
      if (!isCollegue()) {
        if (role.id == 20) {
          return _.intersection(editUserRoles, [...adminRolesList, 20]).length > 0;
        } if (role.id == 50) {
          return _.intersection(editUserRoles, ((rmeVendorNumbers || []).length > 0 ? [...adminRolesList, 50] : [50] )).length > 0;
        } else {
          if (editUserRoles.length == 1 && (editUserRoles[0] == 20 || editUserRoles[0] == 50)) {
            return false;
          }
          return !!isEditableBySupplier;
        }
      } else {
        return !(modifiableRolesOverrides && modifiableRolesOverrides.length > 0 && !modifiableRolesOverrides.includes(role.id));
      }
    }
  };

  const isRoleCurrentlyApplied = (role) => {
    let isTimeLimited = !!_.get(role, 'isTimeLimited', false);
    let roleId = _.get(role, 'id', false);
    if (isTimeLimited && roleId) {
      let futureRole = _.find(futureRoles, fr => fr.roleId === roleId);
      if (futureRole) {
        let now = moment().format('YYYY-MM-DD');
        let from = moment(futureRole.validFrom).format('YYYY-MM-DD');
        let to = moment(futureRole.validUntil).format('YYYY-MM-DD');
        if (( futureRole.validFrom && now < from ) ||
          ( futureRole.validUntil && to < now ) ||
          ( futureRole.validFrom && futureRole.validUntil && to < from )
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const renderRoleSection = () => {
    let validRole = !isRoleslValid() && showValidationMessage;

    const menu = (
      <Menu>
        {ROLES.filter(obj => getRoleSpecificEditPermission(obj)).map(obj => {
          return (
            <Menu.Item key={obj.id} onClick={() => onAddRole(obj)} disabled={disablePrimaryRoleSelection(obj.id)}>
              {obj.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <React.Fragment>
        <div className="roles-wrapper">
          {roles.map(roleId => {
            let roleObj = _.find(ROLES, o => roleId === o.id);
            let roleName = _.get(roleObj, 'name', '');
            return (
              <div key={roleId} className={`role-box${isRoleCurrentlyApplied(roleObj) ? '' : ' inactive' }`}>
                <div>{roleName}</div>
                {checkConditionWithOr(
                  cardType === 'NEW',
                  checkConditionWithAnd(cardType === 'UPDATE', assignRolePermission)
                ) && getRoleSpecificEditPermission(roleObj) && (
                  <div className="btn-delete-role" onClick={() => onDeleteRole(roleId)}>
                    x
                  </div>
                )}
              </div>
            );
          })}
          {cardType === 'DETAILS' && _.isEmpty(roles) && <div>Not Assigned</div>}
        </div>

        {(cardType === 'NEW' || (cardType === 'UPDATE' && assignRolePermission)) && (
          <React.Fragment>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button className={getValueBasedOnCondition(validRole, 'invalid-field', '')}>
                Assign Role <Icon type="down" />
              </Button>
            </Dropdown>
            {validRole && <div className="validation-msg">Plesae assign a role</div>}
          </React.Fragment>
        )}
        {cardType === 'UPDATE' && !assignRolePermission && (
          <div className="cd-info-msg">You have no permission to assign roles</div>
        )}
      </React.Fragment>
    );
  };

  const renderValiditySection = () => {
    if (checkConditionWithOr(cardType === 'NEW', cardType === 'UPDATE')) {
      const dateFormat = 'MM/DD/YYYY';

      return (
        <DatePicker
          onChange={onChangeDate}
          defaultValue={getValueBasedOnCondition(validTill === 0, null, moment(validTill))}
          format={dateFormat}
        />
      );
    } else {
      return (
        <div className="cd-valid-until">
          {getValueBasedOnCondition(validTill === 0, 'Not Set', moment(validTill).format('MM/DD/YYYY'))}
        </div>
      );
    }
  };
  
  const renderDatePicker = (label, defaultVal, editMode, onChange) => {
      const dateFormat = 'MM/DD/YYYY';
      return (
        <div className='cd-role-config sub'>
          <div className='label'>{label}</div>
          { ( editMode ? <DatePicker
            onChange={onChange}
            defaultValue={defaultVal? moment(defaultVal) : null}
            format={dateFormat}
          /> : <div className='data'>{defaultVal ? moment(defaultVal).format(dateFormat) : 'Not set'}</div> ) }
        </div>
      );
  };

  const renderDeleteConfirmationModal = () => {
    return (
      <Modal
        title={`Do you want to remove ${name}?`}
        visible={showConfirmationModal}
        onCancel={() => setShowConfirmationModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowConfirmationModal(false)}>
            Cancel
          </Button>,
          <Button key="retain" type="warning" onClick={onClickRetain}>
            Retain
          </Button>,
          <Button key="delete" type="danger" onClick={onConfirmDelete}>
            Delete
          </Button>
        ]}
      >
        <p>
          If you want to completely remove existing contact, click 'Delete'. Or, If you want to assign this person to
          any roles later, click 'Retain
        </p>
      </Modal>
    );
  };

  const renderRoleConfigs = () => {
    let userRoleObjs = roles.map(userRole => ROLES.find(ro => ro.id === userRole));
    let roleConfigDivs = [];
    userRoleObjs.forEach(userRoleObj => {
      if (userRoleObj && userRoleObj.isTimeLimited) {

        let futureRole = _.find(futureRoles, x => x.roleId === userRoleObj.id);
        let validFrom = _.get(futureRole, 'validFrom', null);
        let validUntil = _.get(futureRole, 'validUntil', null);

        const onChangeValidRange = (fromOrUntil) => {
          const onValidChange = (newVal) => {
            newVal = newVal ? moment(newVal).format('YYYY-MM-DD 00:00:00') : null;
            let tempFutureRoles = [];
            let roleFound = false;
            futureRoles.forEach((x) => {
              let tempFutureRole = _.cloneDeep(x);
              if (x.roleId == userRoleObj.id) {
                roleFound = true;
                if (fromOrUntil === 'validFrom') {
                  tempFutureRole.validFrom = newVal;
                } else {
                  tempFutureRole.validUntil = newVal;
                }
              }
              tempFutureRoles.push(tempFutureRole);
            });
            if (!roleFound) {
              tempFutureRoles.push({
                id: null,
                roleId: userRoleObj.id,
                validFrom: fromOrUntil === 'validFrom' ? newVal : null,
                validUntil: fromOrUntil !== 'validFrom' ? newVal : null
              });
            }
            setFutureRoles(tempFutureRoles);
          };
          return onValidChange;
        } 

        let editMode = checkConditionWithOr(cardType === 'NEW', cardType === 'UPDATE') && getRoleSpecificEditPermission(userRoleObj);
        roleConfigDivs.push(<div className='cd-role-sub-secton' key={`user-${userRoleObj.id}`}>
          <div className="contact-details-titles sub">{userRoleObj.name} Configs</div>
          <div className='cd-role-config'>
            {renderDatePicker('Valid From', validFrom, editMode, onChangeValidRange('validFrom'))}
            {renderDatePicker('Valid Until', validUntil, editMode, onChangeValidRange('validUntil'))}
          </div>
          { !isFutureRolesValid() && showValidationMessage && <div className='validation-msg'>Wrong Date combination</div> }
        </div>);
      }
    } );

    return roleConfigDivs.length > 0 ? <>{(roleConfigDivs)}</> : '';
  };

  return (
    <React.Fragment>
      {renderDeleteConfirmationModal()}
      <div className="contact-details-overlay" onClick={onClose}></div>
      <div className="contact-details-card">
        <div className="alert-wrapper">{missingRolesAlert()}</div>

        <div className="cd-profile-img" style={{ backgroundColor: _.get(props.contact, 'color', '#fa1616') }}>
          {initials}
        </div>

        {renderTitleSection()}

        <div className="contact-section">
          <div className="contact-details-titles">Contact</div>
          {renderContactSection()}
        </div>
        <div className="role-section">
          <div className="contact-details-titles">Roles</div>
          {renderRoleSection()}
          {renderRoleConfigs()}
        </div>
        <div className="validity-section" style={{display: 'none'}}>
          <div className="contact-details-titles valid-until-title">Valid Until</div>
          {renderValiditySection()}
        </div>

        <div className="action-btn-group">
          {getRelavantButtons(
            cardType,
            onSave,
            onClickEdit,
            onUpdate,
            onClickDeleteContact,
            onCancel,
            updatePermission,
            deletePermission
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
