import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import * as _ from 'lodash';
import AppLoader from 'components/AppLoader';
import { Redirect, withRouter } from 'react-router';

const Contacts = props => {
  const { suvc, userPermissions } = props;

  const perfDash = _.includes(userPermissions, 'CNTAPP.CONTACT_DSH.RETRIVE');
  const perfContacts = _.includes(userPermissions, 'CNTAPP.CONTACT.RETRIVE');

  if (perfDash) {
    return <Redirect to="/suite/contact/admin/dashboard" />;
  }
  if (perfContacts && suvc) {
    return <Redirect to={`/suite/contact/user/${suvc}`} />;
  }

  return <AppLoader show />; // TODO add a view to show if there isn't any previlage attachned
};

export default memo(withTranslation()(withRouter(Contacts)));

Contacts.propTypes = {};
