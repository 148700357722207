import React from 'react';

class ScoreCard extends React.Component {
  render() {
    let { score, title, loading, activateCharts } = this.props;

    let cardColor = 'red';
    if (score >= 50) {
      cardColor = 'yellow';
    }
    if (score >= 80) {
      cardColor = 'green';
    }
    if (loading) {
      cardColor = 'gray';
    }

    return (
      <div className={`score-card ${cardColor}`} onClick={activateCharts}>
        <div className="card-details">
          <div className="card-title">{title}</div>
          <div className="card-score">{score + '%'}</div>
        </div>
        <div className="background-graph"></div>
      </div>
    );
  }
}

export default ScoreCard;
