import { combineReducers } from 'redux';

import user from './UserReducer';
import contacts from './ContactReducer';
import dashboard from './DashboardReducer';
import dashboardDownload from './DashboardDownloadReducer';
import brokers from './BrokerReducer';
import roles from './RoleReducer';

export default combineReducers({
  user,
  roles,
  contacts,
  dashboard,
  dashboardDownload,
  brokers
});
