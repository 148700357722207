const selectBrokerRequests = state => state.brokers.requestsList;
const selectBrokerRequestsFetching = state => state.brokers.isFetchingBrokerRequests;
const selectBrokerRequestPage = state => state.brokers.page;
const selectBrokerRequestPageSize = state => state.brokers.pageSize;
const selectBrokerRequestCount = state => state.brokers.brokerRequestCount;
const selectBrokerFilterOptions = state => state.brokers.filterOptions;

export {
  selectBrokerRequests,
  selectBrokerRequestsFetching,
  selectBrokerRequestPage,
  selectBrokerRequestPageSize,
  selectBrokerRequestCount,
  selectBrokerFilterOptions
};
