import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import * as userActions from 'actions';
import AppLogo from './styles/images/app-logos/contacts-logo.svg';
import styles from './styles/ContactApp.scss';
import AssociateDashboard from './pages/AssociateDashboard';
import SupplierContactsView from './pages/SupplierContactsView';
import BrokerRequests from './pages/BrokerRequests';
import AppLoader from 'components/AppLoader';
import ProtectedRoute from 'components/ProtectedRoute';
import Contacts from 'pages/Contacts';
import _ from 'lodash';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ suvc, userId, userPermissions, actions, userFetching }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    actions.loadUserDetails(userId);
  }, [actions, userId]);

  if (!userFetching && !_.isEmpty(userPermissions)) {
    return (
      <div className="wrapper contacts-wrapper">
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/suite/contact"
              render={() => <Contacts suvc={suvc} userPermissions={userPermissions} />}
            />
            <Route
              exact
              path="/suite/contact/admin/dashboard"
              render={() => (
                <ProtectedRoute component={AssociateDashboard} suvc={suvc} userPermissions={userPermissions} />
              )}
            />
            <Route
              exact
              path="/suite/contact/user/:id"
              render={() => (
                <ProtectedRoute component={SupplierContactsView} suvc={suvc} userPermissions={userPermissions} />
              )}
            />
            <Route
              exact
              path="/suite/contact/brokers"
              render={() => <ProtectedRoute component={BrokerRequests} suvc={suvc} userPermissions={userPermissions} />}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  } else {
    return <AppLoader show />;
  }
}

function mapStateToProps(state) {
  return {
    suvc: state.user.data.suvc,
    user: state.user.data,
    userPermissions: state.user.data.permissions,
    userFetching: state.user.fetching
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
