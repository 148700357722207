import initialState from 'store/initialState';
import { createReducer } from 'reduxHelpers';
import { getArrayIndexForSpecificKey } from '../lib/Helpers';

import { BROKERREQUESTS, ONBOARDBROKER } from 'actions/actionTypes';

const updateItemIndexFields = (dataList, key, value, updatingDataSet) => {
  const itemIndex = getArrayIndexForSpecificKey(dataList, key, value);
  if (itemIndex > -1) {
    const updatedItem = { ...dataList[itemIndex], ...updatingDataSet };
    return [...dataList.slice(0, itemIndex), updatedItem, ...dataList.slice(itemIndex + 1)];
  }
  return dataList;
};

const brokers = (state = initialState.brokerRequests, action) => {
  switch (action.type) {
    case BROKERREQUESTS.REQUEST:
      return { ...state, isFetchingBrokerRequests: true, error: null };
    case BROKERREQUESTS.SUCCESS:
      const {
        payload: { brokerCount, brokers, page, orderBy = null, searchData = [] }
      } = action;
      return {
        ...state,
        isFetchingBrokerRequests: false,
        requestsList: brokers,
        brokerRequestCount: brokerCount,
        page,
        filterOptions: { ...state.filterOptions, searchFilters: searchData, orderBy }
      };
    case BROKERREQUESTS.FAILURE:
      return { ...state, isFetchingBrokerRequests: false, error: action.payload };
    case ONBOARDBROKER.REQUEST:
      return {
        ...state,
        requestsList: [...updateItemIndexFields(state.requestsList, 'id', action.data.id, { isUpdating: true })]
      };
    case ONBOARDBROKER.SUCCESS:
      return {
        ...state,
        requestsList: [...updateItemIndexFields(state.requestsList, 'id', action.id, { status: 1, isUpdating: false })]
      };
    case ONBOARDBROKER.FAILURE:
      return {
        ...state,
        requestsList: [...updateItemIndexFields(state.requestsList, 'id', action.id, { isUpdating: false })]
      };
    default:
      return state;
  }
};

createReducer(BROKERREQUESTS, initialState.brokerRequests);

export default brokers;
