import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Input, Table, Button, Tooltip } from 'antd';
import * as _ from 'lodash';

import * as userActions from 'actions';
import AppLoader from 'components/AppLoader';
import ContactDetailsCard from './../components/ContactDetailsCard';
import ScoreCard from '../components/ScoreCard';
import ContactCharts from '../components/ContactCharts';
import { PERMISSIONS, TOOLTIP_CONTENT } from '../lib/Constants';

const {
  CNTAPP: {
    CONTACT: { CREATE, CONTACT_RETRIVE }
  }
} = PERMISSIONS;
const { Search } = Input;

export class SupplierContactsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedContactId: null,
      showAddNewContactPane: false,
      searchTerm: '',
      showChart: false
    };
  }

  componentDidMount() {
    const retrivePermission = _.includes(this.props.userPermissions, CONTACT_RETRIVE);
    let suvc = this.props.match.params.id;

    if (retrivePermission && suvc) {
      this.props.actions.fetchContacts(suvc);
    }
  }

  hideDetails = () => {
    this.setState({ selectedContactId: null, showAddNewContactPane: false });
  };

  toggleChart = () => {
    this.setState(state => {
      return { showChart: !state.showChart };
    });
  };

  onSelectContact = contact => {
    const createPermission = _.includes(this.props.userPermissions, CREATE);

    if (contact.isMissingContact) {
      this.setState({ selectedContactId: null, showAddNewContactPane: createPermission });
    } else if (contact.isInnerRow) {
      // Do nothing
    } else {
      this.setState({ selectedContactId: contact.contactId, showAddNewContactPane: false });
    }
  };

  onSearch = e => {
    this.setState({ searchTerm: _.lowerCase(e.target.value) });
  };

  onClickNewContact = () => {
    this.setState({ showAddNewContactPane: true, selectedContactId: null });
  };

  getProfileImageText = name => {
    let initials = 'NC';

    if (!_.isEmpty(name)) {
      let arr = name.split(' ');
      initials = arr[0].charAt(0);

      if (arr.length >= 2) {
        initials += arr[1].charAt(0);
      }
    }

    return initials.toUpperCase();
  };

  getMissingRoles = () => {
    let missingRoles = [];
    this.props.tableData.forEach(contact => {
      if (contact.isMissingContact) {
        missingRoles.push(contact.role);
      }
    });
    return missingRoles;
  };

  getTableData = () => {
    //let contacts = this.props.tableData;

    let requiredContacts = _.filter(this.props.tableData, x => `${x.type}`.toLowerCase() == 'required');
    let nonRequiredContacts = _.filter(this.props.tableData, x => `${x.type}`.toLowerCase() != 'required');
    let contacts = [...requiredContacts, ...nonRequiredContacts];

    if (_.isEmpty(this.state.searchTerm)) {
      return contacts;
    } else {
      let results = [];

      _.forEach(contacts, contact => {
        let contactName = _.lowerCase(contact.name);
        if (contactName.includes(this.state.searchTerm) && !contact.isMissingContact) {
          results.push(contact);
        }
      });

      return results;
    }
  };

  getTooltipMessage = roleId => {
    return TOOLTIP_CONTENT[roleId];
  };

  getContactScore = () => {
    let contactCount = 0;
    let allRoles = [];
    let contacts = this.props.contacts;

    if (contacts) {
      _.forEach(contacts, obj => {
        allRoles = [...new Set([...allRoles, ...obj.roles])];
      });

      if (_.includes(allRoles, 1)) contactCount++;
      if (_.includes(allRoles, 2)) contactCount++;
      if (_.includes(allRoles, 4)) contactCount++;
    }

    return contactCount === 3 ? 100 : parseInt((contactCount / 3) * 100);
  };

  renderHeader = () => {
    const perfDash = _.includes(this.props.userPermissions, 'CNTAPP.CONTACT_DSH.RETRIVE');

    return (
      <div className="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = !perfDash ? '/suite/dashboard' : '/suite/contact/admin/dashboard';
          }}
        />
        <div className="bread-crum-seperator"></div>
        <div className="bread-crum-caption">Contact Management</div>
        {/* <div className="title">
          <Tag className="tag action-required"><Icon type="warning" />Action Required</Tag>
        </div> */}
        <div className="subtitle">
          The following is a list of contacts within your organization. Please add new contacts for available roles to
          invite your colleagues into the portal.
        </div>
      </div>
    );
  };

  renderScorecard = () => {
    let contactScore = this.getContactScore();

    return (
      <div className="score-cards">
        <div className="cards">
          <ScoreCard
            actions={this.props.actions}
            cardType={'contact'}
            title={'CONTACT'}
            score={contactScore}
            loading={false}
            activateCharts={this.toggleChart}
          />
        </div>
        {this.state.showChart && <ContactCharts contacts={this.props.contacts} contactScore={contactScore} />}
      </div>
    );
  };

  renderNameCell = (text, record) => {
    let initials = this.getProfileImageText(text);
    let email = _.get(record, 'email', '');
    let roles = _.get(record, 'roles', []);
    let showAdminTag = _.intersection(roles, [1, 2, 4]).length > 0;

    if (!_.isEmpty(text)) {
      return (
        <div className="name-cell">
          <div className="cl-profile-img" style={{ backgroundColor: record.color }}>
            {initials}
          </div>
          <div>
            <div className="name-cell-text">{text}</div>
            <div>{email}</div>
          </div>
          {showAdminTag && <div className="admin-tag">Admin</div>}
        </div>
      );
    } else {
      return <div />;
    }
  };

  renderRolesCell = (index, record) => {
    let roleId = record.role;
    let roleName = '';
    let des = this.getTooltipMessage(roleId);

    if (roleId === -1) {
      roleName = 'Not Assigned';
    } else {
      let role = _.find(this.props.roles, o => roleId === o.id);
      roleName = role && role.name;
    }

    return (
      <div className="role-cell">
        {roleId && roleId !== -1 && (
          <Tooltip placement="right" title={des}>
            <div className="tooltip-icon">i</div>
          </Tooltip>
        )}
        <div className="role-cell-text">{roleName}</div>
      </div>
    );
  };

  renderContactsTable = () => {
    let roleFilters = this.props.roles.map(x => {
      return {
        value: x.id,
        text: x.name
      };
    });

    roleFilters.push({ value: -1, text: 'Not Assigned' });

    const typeFilters = [{ text: 'Optional', value: 'Optional' }, { text: 'Required', value: 'Required' }];

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        className: 'name-column',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) => this.renderNameCell(text, record)
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: '25%',
        className: 'role-column',
        filters: roleFilters,
        sorter: (a, b) => {
          let aRole = _.find(this.props.roles, o => a.role === o.id);
          let bRole = _.find(this.props.roles, o => b.role === o.id);
          let aName = _.get(aRole, 'name', 'Not Assigned');
          let bName = _.get(bRole, 'name', 'Not Assigned');

          return aName.localeCompare(bName);
        },
        onFilter: (value, record) => {
          if (value === -1) {
            return record.role === value;
          } else {
            return _.includes(record.roles, value);
          }
        },
        render: (text, record, index) => this.renderRolesCell(index, record)
      },
      {
        title: 'Type',
        dataIndex: 'type',
        className: 'type-column',
        filters: typeFilters,
        onFilter: (value, record) => {
          let req = this.props.roles.filter(x => x.type === 'Required').map(x => x.id);
          let opt = this.props.roles.filter(x => x.type !== 'Required').map(x => x.id);
          let arr = value === 'Required' ? req : opt;

          let intersections = _.intersection(arr, record.roles);

          if (!_.isEmpty(intersections)) return record;
        },
        sorter: (a, b) => {
          let aRole = _.find(this.props.roles, o => a.role === o.id);
          let bRole = _.find(this.props.roles, o => b.role === o.id);
          let aType = _.get(aRole, 'type', '-');
          let bType = _.get(bRole, 'type', '-');

          return aType.localeCompare(bType);
        }
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={this.getTableData()}
        rowKey={'contactId'}
        pagination={false}
        onRow={record => {
          return {
            onClick: event => {
              this.onSelectContact(record);
            }
          };
        }}
      />
    );
  };

  render() {
    const createPermission = _.includes(this.props.userPermissions, CREATE);
    const suvc = this.props.match.params.id;

    if (!this.props.isLoading) {
      return (
        <React.Fragment>
          {this.renderHeader()}
          {this.renderScorecard()}

          <div className="supplier-contacts-view">
            <div className="contacts-pane" style={{ marginTop: this.state.showChart ? '0px' : '30px' }}>
              <div className="contacts-pane-wrapper">
                <div className="contacts-search-wrapper">
                  <Search className="search-box" placeholder="Search contacts" onChange={this.onSearch} enterButton />
                </div>
                {createPermission && (
                  <Button className="footer-btn btn-confirm" onClick={this.onClickNewContact}>
                    New Contact
                  </Button>
                )}
              </div>
              {this.renderContactsTable()}
            </div>

            {/* Show add new contact pane */}
            {this.state.showAddNewContactPane && (
              <ContactDetailsCard
                suvc={suvc}
                cardType={'NEW'}
                missingRoles={this.getMissingRoles()}
                onClose={this.hideDetails}
                getProfileImageText={this.getProfileImageText}
                createContact={this.props.actions.createContact}
              />
            )}

            {/* Show contact details pane */}
            {!_.isEmpty(this.state.selectedContactId) && (
              <ContactDetailsCard
                suvc={suvc}
                cardType={'DETAILS'}
                contact={_.find(this.props.tableData, o => this.state.selectedContactId === o.contactId)}
                missingRoles={this.getMissingRoles()}
                getProfileImageText={this.getProfileImageText}
                onClose={this.hideDetails}
                updateContact={this.props.actions.updateContact}
                deleteContact={this.props.actions.deleteContact}
              />
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return <AppLoader show />;
    }
  }
}

function mapStateToProps(state) {
  return {
    tableData: state.contacts.tableData,
    contacts: state.contacts.contacts,
    isLoading: state.contacts.fetching,
    userPermissions: state.user.data.permissions,
    roles: state.roles.list
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(SupplierContactsView)));
