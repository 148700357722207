import React from 'react';
import { Select, Menu } from 'antd';
import moment from 'moment';
import * as _ from 'lodash';
import {
  PHONE_NUMBER_TYPES,
  CONTACT_TYPES,
  BROKER_ONBOARD_TITLES,
  BROKER_REQUESTS_EXPANDED_DISPLAY_FIELDS
} from './Constants';

const listPhoneNumberTypesOptions = () => {
  const { Option } = Select;
  return PHONE_NUMBER_TYPES.map((value, index) => {
    return (
      <Option key={index + 1} value={value}>
        {value}
      </Option>
    );
  });
};

const listDownLinkingContactTypes = (linkAction, contactId) => {
  return CONTACT_TYPES.map((value, index) => {
    const indexIncreasedByOne = index + 1;
    if (contactId !== indexIncreasedByOne) {
      return (
        <Menu.Item key={indexIncreasedByOne} onClick={linkAction()}>
          {value}
        </Menu.Item>
      );
    }
  });
};

const getValueBasedOnCondition = (condition, trueValue, falseValue) => {
  return condition ? trueValue : falseValue;
};

const checkConditionWithOr = (condition, secondCondition) => condition || secondCondition;

const checkConditionWithAnd = (condition, secondCondition) => condition && secondCondition;

const checkConditionWithThreeOr = (condition, secondCondition, thirdCondition) =>
  condition || secondCondition || thirdCondition;

const getBrokerRequestFieldValues = (field, value) => {
  if (['created_at', 'updated_at'].includes(field)) return moment(value).format('MM/DD/YYYY');
  return value;
};

const makeDataTableForBrokerRequestExpanded = dataSet => {
  return Object.keys(dataSet)
    .filter(key => BROKER_REQUESTS_EXPANDED_DISPLAY_FIELDS.includes(key))
    .map(index => {
      return {
        key: index,
        fieldName: BROKER_ONBOARD_TITLES[index],
        value: getBrokerRequestFieldValues(index, dataSet[index])
      };
    });
};

const getRoleList = () => {
  let list = localStorage.getItem('role-list') || '[]';
  return JSON.parse(list);
};

const parseJson = data => {
  if (typeof data === 'object') return data;
  return parseJson(JSON.parse(data));
};

const getArrayIndexForSpecificKey = (dataSet, key, value) => {
  return dataSet.findIndex(eachItem => eachItem[key] === value);
};

const getSortOrder = (columnName, orderBy) => {
  const field = _.get(orderBy, 'param', null);
  let order = _.get(orderBy, 'val', false);
  if (order === 'asc') {
    order = 'ascend';
  } else if (order === 'desc') {
    order = 'descend';
  }
  if (field && field === columnName) {
    return order;
  }
  return false;
};

const checkIsNotEmptyAndAppendUri = (data, key, uri) => {
  if (!_.isEmpty(data)) return `${uri}&${key}=${encodeURIComponent(JSON.stringify(data))}`;
  return uri;
};

const getSpreadedArrayOrDefault = (dataArray, defaultValue = []) => {
  return dataArray ? [...dataArray] : defaultValue;
};

export {
  listPhoneNumberTypesOptions,
  listDownLinkingContactTypes,
  getValueBasedOnCondition,
  checkConditionWithOr,
  checkConditionWithAnd,
  checkConditionWithThreeOr,
  getBrokerRequestFieldValues,
  makeDataTableForBrokerRequestExpanded,
  parseJson,
  getArrayIndexForSpecificKey,
  getSortOrder,
  checkIsNotEmptyAndAppendUri,
  getSpreadedArrayOrDefault,
  getRoleList
};
