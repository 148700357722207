import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import * as _ from 'lodash';

class ProtectedRoute extends React.Component {
  getRouteVariables = (suvc, pathSuvc, userPermissions, path) => {
    if (path === '/suite/contact/admin/dashboard' && _.includes(userPermissions, 'CNTAPP.CONTACT_DSH.RETRIVE'))
      return [true, path];
    if (path === '/suite/contact/user/:id') {
      if (_.includes(userPermissions, 'CNTAPP.CONTACT_DSH.RETRIVE') && pathSuvc)
        return [true, `/suite/contact/user/${pathSuvc}`];
      if (_.includes(userPermissions, 'CNTAPP.CONTACT.RETRIVE') && suvc == pathSuvc)
        return [true, `/suite/contact/user/${suvc}`];
    }
    if (path === '/suite/contact/brokers' && _.includes(userPermissions, 'CNTAPP.BROKERONBOARD'))
      return [true, `/suite/contact/brokers`];
    return [false, '/suite/dashboard'];
  };

  render() {
    const Component = this.props.component;
    const { userPermissions, match } = this.props;
    const path = match.path;

    let pathSuvc = _.get(this.props.match, 'params.id', null);
    let suvc = this.props.suvc;

    const [isAuthorized, redirectPath] = this.getRouteVariables(suvc, pathSuvc, userPermissions, path);

    return isAuthorized ? <Component /> : <Redirect to={{ pathname: redirectPath }} />;
  }
}

export default withRouter(ProtectedRoute);
