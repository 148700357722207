/* eslint-disable camelcase */
import { put } from 'redux-saga/effects';
import * as _ from 'lodash';

import { getRequest, patchRequest } from '_http';
import { BROKERREQUESTS, ONBOARDBROKER } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

import { checkIsNotEmptyAndAppendUri, getSpreadedArrayOrDefault } from '../../lib/Helpers';

function* loadBrokerRequestsAsync({ data: { page, pageSize, orderBy, searchData } }) {
  try {
    let uri = `/user/brokers/requests?page=${page}&size=${pageSize}`;
    uri = checkIsNotEmptyAndAppendUri(orderBy, 'orderby', uri);
    uri = checkIsNotEmptyAndAppendUri(getSpreadedArrayOrDefault(searchData), 'filters', uri);
    const response = yield getRequest(uri);
    yield put({ type: BROKERREQUESTS.SUCCESS, payload: { ...response.data, page, orderBy, searchData } });
  } catch (error) {
    yield put({ type: BROKERREQUESTS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'Broker Requests Loading Error'
      })
    );
  }
}

function* onboardBrokerAsync({ data: { email, fields, id, mobile_number, type, status, created_at, updated_at } }) {
  try {
    yield patchRequest(`/user/brokers/requests`, {
      id,
      email,
      mobile_number,
      fields,
      type,
      status,
      created_at,
      updated_at
    });
    yield put({ type: ONBOARDBROKER.SUCCESS, id });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Broker Successfully Onboarded!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: ONBOARDBROKER.FAILURE, id });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'Broker Onboarding Error'
      })
    );
  }
}

export { loadBrokerRequestsAsync, onboardBrokerAsync };
