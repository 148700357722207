import initialState from 'store/initialState';
import * as _ from 'lodash';

import { CONTACTS, UPDATECONTACT, CREATECONTACT, DELETECONTACTBYID } from 'actions/actionTypes';
import { getRoleList } from 'lib/Helpers';

const contacts = (state = initialState.contacts, action) => {
  switch (action.type) {
    case CONTACTS.REQUEST:
      return { ...state, fetching: true, error: null };
    case CONTACTS.SUCCESS:
      let tableData1 = formatTableData(addFutureRolesToContacts(action.payload));
      return { ...state, fetching: false, contacts: addFutureRolesToContacts(action.payload), tableData: tableData1, rme_vendors: action.rme_vendors };
    case CONTACTS.FAILURE:
      return { ...state, fetching: false, data: [], error: action.error };
    case CREATECONTACT.REQUEST:
      return { ...state, fetching: true, error: null };
    case CREATECONTACT.SUCCESS:
      let contacts1 = [...state.contacts, addFutureRolesToOneContact(action.payload)];
      let tableData2 = formatTableData(contacts1);
      return { ...state, contacts: contacts1, tableData: tableData2, fetching: false };
    case CREATECONTACT.FAILURE:
      return { ...state, fetching: false, error: action.error };
    case UPDATECONTACT.REQUEST:
      return { ...state, fetching: true, error: null };
    case UPDATECONTACT.SUCCESS:
      let contacts2 = [...state.contacts];
      let index = _.findIndex(contacts2, o => {
        return action.payload.contactId === o.contactId;
      });
      if (index !== -1) {
        contacts2[index] = { ...state.contacts[index], ...addFutureRolesToOneContact(action.payload.contact) };
      }
      let tableData3 = formatTableData(contacts2);
      return { ...state, contacts: contacts2, tableData: tableData3, fetching: false };
    case DELETECONTACTBYID.REQUEST:
      return { ...state, fetching: true, error: null };
    case DELETECONTACTBYID.SUCCESS:
      let contacts3 = _.filter([...state.contacts], obj => action.payload.contactId !== obj.contactId);
      let tableData4 = formatTableData(contacts3);
      return { ...state, contacts: contacts3, tableData: tableData4, fetching: false };
    case DELETECONTACTBYID.FAILURE:
      return { ...state, fetching: false, error: action.error };
    case UPDATECONTACT.FAILURE:
      return { ...state, fetching: false, error: action.error };
    default:
      return state;
  }
};

const addFutureRolesToContacts = (contacts) => {
  return contacts.map(c => addFutureRolesToOneContact(c));
};

const addFutureRolesToOneContact = (contact) => {

  let pushedRoles = [];
  if (contact.futureRoles) {
    let futureRoles = contact.futureRoles;
    for (let i=0; i < futureRoles.length; i++) {
      let futureRole = futureRoles[i];

      if (!contact.roles.includes(futureRole.roleId)) {
        contact.roles.push(futureRole.roleId);
        pushedRoles.push(pushedRoles);
      }
    }
  }
  contact.pushedRoles = pushedRoles;
  return contact;
};

const getFirstRoleFromArray = roles => {
  return roles.sort((a, b) => a - b)[0];
};

function getContactTypeRequiredOrOptional(role) {
  let roleObj = getRoleList().find(x => x.id === role);
  return roleObj ? roleObj.type : 'Optional';
}

// Format data, so that the table can show multiple roles
function formatTableData(contacts) {
  let data = [];
  let existingRoles = [];
  let roleIds = getRoleList().map(x => x.id);

  _.forEach(contacts, obj => {
    let contact = { ...obj };
    let contactId = contact.contactId;

    if (!_.isEmpty(contact.roles)) {
      let roles = sortByRolePriority(contact.roles, true); // Sort inner rows according to role priority
      const role = getFirstRoleFromArray(roles);
      contact.role = role;
      contact.type = getContactTypeRequiredOrOptional(role);

      if (roles.length >= 2) {
        let children = [];
        // sort roles array and only get all roles without the role that was taken
        _.forEach(roles.sort((a, b) => a - b).filter(value => value != role), (roleId, index) => {
          children.push({
            contactId: contactId + '-' + roleId,
            name: '',
            role: roleId,
            type: getContactTypeRequiredOrOptional(roleId),
            isInnerRow: true
          });
        });

        contact.children = children;
      }
    } else {
      contact.role = -1;
      contact.type = '-';
    }

    data.push(contact);
    existingRoles = [...existingRoles, ...contact.roles];
  });

  let missingRoles = _.difference(roleIds, existingRoles);
  let sortedData = sortByRolePriority(data); // Sort main rows according to role priority
  let missingContacts = [];

  _.forEach(missingRoles, roleId => {
    let missingContact = {
      contactId: `missing-${roleId}`,
      name: 'No Contact',
      email: 'No email',
      role: roleId,
      type: getContactTypeRequiredOrOptional(roleId),
      isMissingContact: true
    };

    missingContacts.push(missingContact);
  });

  let sortedMissingContacts = sortByRolePriority(missingContacts); // Sort the missing contacts according to role priority
  let allSortedContacts = sortedData.concat(sortedMissingContacts);
  let formatedData = setProfileImageColor(allSortedContacts);

  // sort formatted contact list via role
  let sortedFormattedData = formatedData.sort((a, b) => {
    if (a.role < b.role) {
      return -1; // a should be placed before b
    } else if (a.role > b.role) {
      return 1; // a should be placed after b
    } else {
      return 0; // a and b are considered equal
    }
  });

  const unassignedValues = sortedFormattedData.filter(obj => obj.role == -1);

  const assignedValues = sortedFormattedData.filter(obj => obj.role != -1);

  return [...assignedValues, ...unassignedValues];
}

function sortByRolePriority(data, isInnerRow) {
  let ordering = {};
  let sortOrder = getRoleList().map(x => x.id);
  sortOrder.push(-1);

  for (let i = 0; i < sortOrder.length; i++) {
    ordering[sortOrder[i]] = i;
  }

  let sorted = data.sort((a, b) => {
    if (isInnerRow) {
      return ordering[a] - ordering[b];
    } else {
      return ordering[a.role] - ordering[b.role];
    }
  });

  return sorted;
}

function setProfileImageColor(contacts) {
  const COLORS = ['#ff7c7c', '#11698e', '#5eaaa8', '#fbd46d'];

  _.forEach(contacts, (contact, index) => {
    if (contact.isMissingContact) {
      contact.color = '#fa1616';
    } else {
      let i = index % COLORS.length;
      contact.color = COLORS[i];
    }
  });

  return contacts;
}

export default contacts;
