import { all } from 'redux-saga/effects';
import contactSaga from './contactSaga';
import dashboardSaga from './dashboardSaga';
import notificationSaga from './notificationSaga';
import userSaga from './userSaga';
import brokerSaga from './brokerSaga';

export default function* rootSaga() {
  yield all([...contactSaga, ...dashboardSaga, ...notificationSaga, ...userSaga, ...brokerSaga]);
}
