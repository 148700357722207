import initialState from 'store/initialState';
import { createReducer } from 'reduxHelpers';

import { DASHBOARD } from 'actions/actionTypes';

const dashboard = (state = initialState.dashboard, action) => {
  switch (action.type) {
    case DASHBOARD.REQUEST:
      return { ...state, fetching: true, error: null };
    case DASHBOARD.SUCCESS:
      let { data, page, pageSize, searchTerm } = action.payload;
      return { ...state, fetching: false, data, page, pageSize, searchTerm };
    case DASHBOARD.FAILURE:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
};

createReducer(DASHBOARD, initialState.dashboard);

export default dashboard;
