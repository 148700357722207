import initialState from 'store/initialState';
import { createReducer } from 'reduxHelpers';

import { ROLES } from 'actions/actionTypes';

const getOnlyCMRoles = (roles) => {
    roles = roles || [];
    let roleList = [];

    for (let i=0; i<500; i++) {
        let selected = roles.find(r => r.cmIsVisible === 1 && r.cmDisplayOrder === i);
        if (selected) {
            roleList.push(selected);
        }
    }
    
    const mappedRoleList = roleList.map(r => {
        return {
            id: r.id,
            name: r.cmDisplayName || '',
            type: r.cmIsRequired === 1 ? 'Required': 'Optional',
            isEditableBySupplier: r.cmIsEditableBySupplier ? 1 : 0,
            isTimeLimited: r.cmIsTimeLimited ? 1 : 0,
            roleAdmins: r.cmRoleAdmins ? r.cmRoleAdmins.split(',').map(x => parseInt(x)) : [],
            modifiableRolesOverride: r.cmModifiableRolesOverride || null
        };
    });

    localStorage.setItem('role-list', JSON.stringify(mappedRoleList));

    return mappedRoleList;
};

const roles = (state = initialState.roles, action) => {
  switch (action.type) {
    case ROLES.SUCCESS:
      let list = getOnlyCMRoles(action.payload);
      return { ...state, list };
    default:
      return state;
  }
};

createReducer(ROLES, initialState.roles);

export default roles;
