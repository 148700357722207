import React, { useCallback, useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { fetchBrokerRequests } from '../../actions';

const TableSearch = ({ searchFilters, field, pageSize, orderBy, isBrokerRequestsListFetching }) => {
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    setSearchData(searchFilters);
  }, [searchFilters]);

  const fetchAllBrokerRequests = useCallback(
    data => {
      dispatch(fetchBrokerRequests(data));
    },
    [dispatch]
  );

  const target = _.find(searchData, ({ param }) => field === param);
  const searchFieldValue = _.get(target, 'val[0]', '');

  const onTableSearchInputChange = (columnName, value) => {
    let clone = [...searchData];
    const filteredObject = _.find(clone, obj => columnName === obj.param);

    if (!_.isEmpty(value)) {
      if (filteredObject) {
        filteredObject.val = [value];
      } else {
        clone.push({ param: columnName, val: [value], operator: 'like' });
      }
    } else {
      clone = _.filter(clone, obj => columnName !== obj.param);
    }
    setSearchData(clone);
  };

  const onTableSearchClear = () => {
    const searchDataAfterRemovingCurrentFieldSearch = _.filter([...searchData], obj => field !== obj.param);
    fetchAllBrokerRequests({ orderBy, page: 1, pageSize, searchData: searchDataAfterRemovingCurrentFieldSearch });
    setSearchData(searchDataAfterRemovingCurrentFieldSearch);
  };

  return (
    <div className="table-search-dropdown">
      <Input
        className="table-search-input"
        value={searchFieldValue}
        onChange={e => onTableSearchInputChange(field, e.target.value)}
        onPressEnter={() => fetchAllBrokerRequests({ orderBy, page: 1, pageSize, searchData })}
      />
      <div className="table-search-btn-wrapper">
        <Button
          className="table-search-btn"
          type="primary"
          disabled={isBrokerRequestsListFetching}
          onClick={() => fetchAllBrokerRequests({ orderBy, page: 1, pageSize, searchData })}
        >
          Search
        </Button>
        <Button
          className="table-search-btn-reset"
          disabled={isBrokerRequestsListFetching}
          onClick={() => onTableSearchClear()}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default TableSearch;
