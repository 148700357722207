import React from 'react';
const CONTACT_TYPES = ['Primary', 'Secondary', 'Executive', 'Master Data', 'Marketing', 'Image'];

const COLORS = {
  YELLOW: 'yellow',
  GREEN: 'green',
  GRAY: 'gray',
  RED: 'red'
};

const PHONE_NUMBER_TYPES = ['Work', 'Mobile', 'Home', 'Primary'];

const PERMISSIONS = {
  CNTAPP: {
    CONTACT: {
      LINK: 'CNTAPP.CONTACT.LINK',
      OTHER_UPDATE: 'CNTAPP.CONTACT.OTHER_UPDATE',
      OTHER_DELETE: 'CNTAPP.CONTACT.OTHER_DELETE',
      UPDATE: 'CNTAPP.CONTACT.UPDATE',
      DELETE: 'CNTAPP.CONTACT.DELETE',
      CREATE: 'CNTAPP.CONTACT.CREATE',
      SELF_UPDATE: 'CNTAPP.CONTACT.SELF_UPDATE',
      SELF_DELETE: 'CNTAPP.CONTACT.SELF_DELETE',
      DASHBOARD_RETRIVE: 'CNTAPP.CONTACT_DSH.RETRIVE',
      CONTACT_RETRIVE: 'CNTAPP.CONTACT.RETRIVE'
    }
  }
};

const BROKER_ONBOARD_TITLES = {
  id: 'ID',
  title: 'Title',
  name: 'Name',
  created_at: 'Created At',
  updated_at: 'Updated At',
  mobile_number: 'Mobile Number',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  officePhone: 'Office Phone',
  supplier_sample_customer_number: 'Supplier Sample Customer Number',
  supplier_broker_name: 'Supplier Broker Name',
  address1: 'Address 1',
  address2: 'Address 2',
  country: 'Country',
  city: 'City',
  state: 'State',
  zipcode: 'Zip Code',
  brokerName: 'Broker Name',
  sscn: 'Supplier Sample Customer Number'
};

const INITIAL_BROKER_REQUEST_PAGE_SIZE = 10;

const BROKER_REQUESTS_EXPANDED_DISPLAY_FIELDS = [
  'firstName',
  'lastName',
  'officePhone',
  'email',
  'sscn',
  'brokerName',
  'title',
  'address1',
  'address2',
  'country',
  'city',
  'state',
  'zipcode',
  'mobile_number'
];

const TOOLTIP_CONTENT = {
  1: (
    <div className="role-tool-tip">
      The person responsible for the overall commercial relationship with Sysco. This role is best suited for someone
      with the following responsibilities/characteristics:
      <ul>
        <li>Strategically responsible for connectivity across all functions within the Supplier organization </li>
        <li>Develops strategic direction on behalf of the supplier’s organization</li>
        <li>Typically, not the first point of contact for non-urgent activity</li>
        <li> Responsible for executive-level business development and investment strategy related to Sysco </li>
      </ul>
    </div>
  ),
  2: (
    <div className="role-tool-tip">
      This person is the alternative to the primary with overall day to day commercial activities with Sysco and will
      have similar tactical responsibilities:
      <ul>
        <li>Tactically responsible for connectivity across all functions within supplier organization</li>
        <li>
          Possesses deep knowledge in both Sysco as well as supplier processes and commercialrelationship, is the go-to
          for getting something done
        </li>
        <li>Typically, the first point of contact for non-urgent activity</li>
        <li>Often director-level representative responsible for corporate to corporate activities</li>
      </ul>
    </div>
  ),
  3: <div className="role-tool-tip">C-level contact between Sysco and the Supplier leadership teams.</div>,
  4: (
    <div className="role-tool-tip">
      The individual responsible for maintaining all information related to Item and Supplier master data (e.g., item
      sync w/ GS1, updating attribution information in the new system, etc.):
      <ul>
        <li>This role is best suited for a product and/or GS1 expert</li>
        <li>
          This role is responsible for populating product information in the new system and/or coordinating with the
          appropriate Product Experts within your organization to obtain thisinformation
        </li>
      </ul>
    </div>
  ),
  5: (
    <div className="role-tool-tip">
      The individual responsible for providing the marketing information about the product such as, Features & Benefits
      statements, corporate 3rd line description, etc. These are any additional information that will help customers
      make informed decisions.
    </div>
  ),
  6: (
    <div className="role-tool-tip">
      The individual responsible for providing image content about the product. Should follow the GS1 standards for
      image style.
    </div>
  ),
  7: <div className="role-tool-tip">Finance Point of Contact</div>,
  8: <div className="role-tool-tip">
    This person has high-level oversight and strategic direction for ensuring products and processes meet regulatory requirements and quality standards. This role is best suited for someone who:
    <ul>
      <li>Provides strategic leadership in establishing, implementing, and maintaining quality assurance and compliance programs across the supplier's organization.</li>
      <li>Ensures supplier's organization complies with relevant federal, provinical and local regulatory requirements, industry standards, and best practices. This involves staying updated on regulation changes and collaborating with Sysco to maintain product compliance.</li>
      <li>Leads risk management efforts by identifying, assessing, and mitigating risks related to quality, compliance, and regulatory affairs.</li>
    </ul>
  </div>,
  9: (
    <div className="role-tool-tip">
      This individual is responsible for the Sustainability relationship with Sysco. This role is best suited for
      someone with the following responsibilities/characteristics:
      <ul>
        <li>
          Act as the subject matter expert related to company sustainability programs and products (e.g., greenhouse gas
          measurement, science-based targets, responsible sourcing programs).
        </li>
        <li>Serve as a key contact for supplier inclusion and diversity program information.</li>
        <li>Identify and support partnership opportunities as it relates to sustainability.</li>
      </ul>
    </div>
  ),
  10: (
    <div className="role-tool-tip">
      This individual is responsible for day to day activities with Sysco in supporting operational activities
    </div>
  ),
  11: (
    <div className="role-tool-tip">
      eCommerce Point of Contact is responsible for driving digital strategy plans and execution between Sysco and our
      supplier partner
    </div>
  ),
  12: (
    <div className="role-tool-tip">
      The person responsible for a Supplier Cost Administrator relationship with Sysco. This role is best suited for
      someone with the following responsibilities/characteristics:
      <ul>
        <li>Manage communication with Sysco regarding supplier price management requests</li>
        <li>
          Submit supplier price lists, market indicators, and Sysco formatted documents to support price modifications
        </li>
        <li>Review negotiated Sysco net costs and programs for analysis and/or audit</li>
        <li>Support supplier cost change negotiations with Sysco</li>
      </ul>
    </div>
  ),
  14: (
    <div className="role-tool-tip">
      The person responsible for providing nutrition specs upon request; answering nutrition-related questions about
      products, including nutrient information, ingredients/allergens, diet types/attributes; assists with GDSN
      nutrition data, if applicable; providing certifications if requested.
    </div>
  ),
  16: <div className="role-tool-tip">This individual has access to PGI tile and data within the platform.</div>,
  20: <div className="role-tool-tip">
    <p>This individual is responsible for ensuring Sysco/Ebix has a compliant Certificate of Insurance (COI) on file.  COI’s expire annually.</p>
    <p>Sysco/EBIX will leverage this contact information in the event we do not receive a compliant COI prior to expiration.</p>
    <p>The role is best suited for someone with the following responsibilities/characteristics:</p>
    <ul>
      <li>Serve as a key contact for COI issues or questions.</li>
      <li>Has direct contact with your COI Insurance Broker (if applicable).</li>
    </ul>
  </div>
};

export {
  CONTACT_TYPES,
  COLORS,
  PHONE_NUMBER_TYPES,
  PERMISSIONS,
  BROKER_ONBOARD_TITLES,
  INITIAL_BROKER_REQUEST_PAGE_SIZE,
  BROKER_REQUESTS_EXPANDED_DISPLAY_FIELDS,
  TOOLTIP_CONTENT
};
