const dashboard = {
  fetching: false,
  data: {
    header: {
      new: {
        contacts: null,
        suppliers: null
      },
      outdated: {
        contacts: null,
        suppliers: null
      },
      missing: {
        contacts: null,
        suppliers: null
      }
    },
    suppliers: [],
    count: 0
  },
  error: null,
  page: 1,
  pageSize: 100,
  searchTerm: null
};

export default dashboard;
