import React from 'react';
import { getBrokerRequestFieldValues, makeDataTableForBrokerRequestExpanded, parseJson } from '../../lib/Helpers';
import { Table, Input, Tag, Pagination, Icon, Radio, DatePicker, TreeSelect, Modal, Button } from 'antd';

const ExpandedContent = ({ record: { status, fields, email, id, mobile_number, created_at, updated_at } }) => {
  const dataSet = {
    email,
    id,
    mobile_number,
    created_at,
    updated_at,
    ...parseJson(fields)
  };
  const tableData = makeDataTableForBrokerRequestExpanded(dataSet);
  const columns = [
    {
      title: 'Field',
      dataIndex: 'fieldName',
      width: 400,
      render: text => <span>{text}</span>
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: 400,
      render: text => <span>{text}</span>
    }
  ];
  return (
    <div className="broker-requests-expanded-table-wrapper">
      <Table bordered pagination={false} dataSource={tableData} columns={columns} />
    </div>
  );
};

export default ExpandedContent;
