import { takeLatest } from 'redux-saga/effects';
import { CONTACTS, UPDATECONTACT, CREATECONTACT, DELETECONTACTBYID, DOWNLOADCONTACTS } from 'actions/actionTypes';
import {
  fetchContactsAsync,
  UpdateContactsAsync,
  CreateContactsAsync,
  DeleteContactsByIdAsync,
  loadDashboardDownloadAsync
} from './ContactSaga';

const contactSaga = [
  takeLatest(CONTACTS.REQUEST, fetchContactsAsync),
  takeLatest(UPDATECONTACT.REQUEST, UpdateContactsAsync),
  takeLatest(CREATECONTACT.REQUEST, CreateContactsAsync),
  takeLatest(DELETECONTACTBYID.REQUEST, DeleteContactsByIdAsync),
  takeLatest(DOWNLOADCONTACTS.REQUEST, loadDashboardDownloadAsync)
];

export default contactSaga;
