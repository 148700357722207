import React, { useEffect, useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';
import { Button, Spin, Table, Icon, Pagination, Select, Input, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { loadDashboardDetails, downloadContact, completeContactDownload } from 'actions';
import * as Papa from 'papaparse';
import AppLoader from '../components/AppLoader';
import downloadCSV from '../components/FileDownload';
import { selectUser } from './../selectors/UserSelector';
import { selectContactsDownloadFetching, selectContactDownload } from './../selectors/DashboardDownloadSelector';
import { selectDashboard, selectDashboardFetching } from './../selectors/DashboardSelector';

const Option = Select.Option;
const { Search } = Input;
const PAGE_SIZES = ['20', '50', '100', 'All'];

const AssociateDashboard = () => {
  const dispatch = useDispatch();

  const { gridScrollHeight } = useState('70vh');

  // selectors

  const user = useSelector(selectUser);
  const contactsDownloadFetching = useSelector(selectContactsDownloadFetching);
  const contactsDownload = useSelector(selectContactDownload);
  const dashboard = useSelector(selectDashboard);
  const dashboardFetching = useSelector(selectDashboardFetching);

  let page = dashboard.page;
  let pageSize = dashboard.pageSize;
  let dataCount = dashboard.data.count;
  let searchTerm = dashboard.searchTerm;

  // actions

  const loadUserDashboardDetails = useCallback(
    (page, pageSize, searchTerm) => {
      dispatch(loadDashboardDetails(page, pageSize, searchTerm));
    },
    [dispatch]
  );

  const downloadUserContact = useCallback(() => {
    dispatch(downloadContact());
  }, [dispatch]);

  const completeUserContactDownload = useCallback(() => {
    dispatch(completeContactDownload());
  }, [dispatch]);

  useEffect(() => {
    if (user.contactId) {
      loadUserDashboardDetails(page, pageSize, searchTerm);
    }
  }, [loadUserDashboardDetails, user]);

  useEffect(() => {
    if (contactsDownload && contactsDownload.vendors && contactsDownload.vendors.length) {
      downloadCSV('contacts', Papa.unparse(contactsDownload.vendors));
      completeUserContactDownload();
    }
  }, [completeUserContactDownload, contactsDownloadFetching, contactsDownload]);

  const downloadContacts = () => {
    downloadUserContact();
  };

  const onPagination = value => {
    loadUserDashboardDetails(value, pageSize, searchTerm);
  };

  const onChangePageSize = value => {
    let size = value === 'All' ? dataCount : value;

    loadUserDashboardDetails(1, size, searchTerm);
  };

  const onSearch = e => {
    loadUserDashboardDetails(1, pageSize, e.target.value);
  };

  const columns = [
    {
      title: 'Supplier Name',
      dataIndex: 'key',
      key: 'key',
      width: '25vw',
      render: (text, record) => <Link to={`/suite/contact/user/${text}`}>{record.suppliername}</Link>
    },
    {
      title: (filters, sortOrder) => (
        <div>
          <i className="fi flaticon-tick header-icons"></i>
          New Contacts
        </div>
      ),
      dataIndex: 'newcontacts',
      className: 'cell-color green',
      key: 'newcontacts',
      width: '12vw'
    },
    {
      title: (filters, sortOrder) => (
        <div>
          <i className="fi flaticon-update-arrows header-icon"></i>
          Outdated Contacts
        </div>
      ),
      dataIndex: 'outdatedcontacts',
      key: 'outdatedcontacts',
      className: 'cell-color yellow',
      width: '12vw'
    },
    {
      title: (filters, sortOrder) => (
        <div>
          <Icon className="header-icon" type="warning" />
          Missing Contacts
        </div>
      ),
      dataIndex: 'missingcontacts',
      key: 'missingcontacts',
      className: 'cell-color red',
      width: 'auto'
    }
  ];

  return !dashboardFetching ? (
    <React.Fragment>
      <div className="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        />
        <div className="bread-crum-seperator"></div>
        <div className="title">Dashboard</div>
        <div className="spacer"></div>
        <div className="download-button-container">
          {/* <div
            className="broker-request-link"
            onClick={() => {
              window.location.href = '/suite/contact/brokers';
            }}
          >
            Broker Requests
          </div> */}
          <Tooltip placement="left" title={<p>
            If you don't have access to the contact management dashboard, request it with this link: <a target='_blank' href='https://sysco.service-now.com/sp?id=sc_cat_item&sys_id=e3ebafeadb4728d0eae0ce613996199a&sysparm_category=13dc0a51dba94910bc38c3af29961969' style={{color: '#008cd2'}}>Tableau Access Request
            </a>
          </p>}><Button type="primary" className="btn green-action-btn" onClick={() => {
            window.open(
              'http://tableau-dev/t/MISB/views/SSSContactManagementDownload_v1/SSSContactManagementDownload_v1',
              '_blank'
            );
          }}>
            Get Contacts
          </Button></Tooltip>
          {/*!contactsDownloadFetching ? (
            <Button type="primary" className="btn green-action-btn" onClick={downloadContacts}>
              <i className="fi flaticon-download"></i>
              Download Report
            </Button>
          ) : (
            <div className="spinner-container">
              <Spin />
            </div>
          )*/}
        </div>
      </div>

      <ul className="dashboard-cards">
        <li className="dashboard-card green">
          <div className="card-header">
            New
            <div className="spacer"></div>
            <i className="fi flaticon-tick"></i>
          </div>
          <div className="card-data">
            <div className="data-column">
              <div className="data">{dashboard.data.header.new.contacts}</div>
              <div className="label">Contacts</div>
            </div>
            <div className="data-column center">
              <div className="label from">from</div>
            </div>
            <div className="data-column right">
              <div className="data">{dashboard.data.header.new.suppliers}</div>
              <div className="label">
                <i className="fi flaticon-team"></i>
                Suppliers
              </div>
            </div>
          </div>
        </li>
        <li className="dashboard-card yellow">
          <div className="card-header">
            UPDATES REQUIRED <div className="spacer"></div>
            <i className="fi flaticon-update-arrows"></i>
          </div>

          <div className="card-data">
            <div className="data-column">
              <div className="data">{dashboard.data.header.outdated.contacts}</div>
              <div className="label">Contacts</div>
            </div>
            <div className="data-column center">
              <div className="label from">from</div>
            </div>
            <div className="data-column right">
              <div className="data">{dashboard.data.header.outdated.suppliers}</div>
              <div className="label">
                <i className="fi flaticon-team"></i>
                Suppliers
              </div>
            </div>
          </div>
        </li>
        <li className="dashboard-card red">
          <div className="card-header">
            MISSING <div className="spacer"></div>
            <Icon type="warning" />
          </div>
          <div className="card-data">
            <div className="data-column">
              <div className="data">{dashboard.data.header.missing.contacts}</div>
              <div className="label">Contacts</div>
            </div>
            <div className="data-column center">
              <div className="label from">from</div>
            </div>
            <div className="data-column right">
              <div className="data">{dashboard.data.header.missing.suppliers}</div>
              <div className="label">
                <i className="fi flaticon-team"></i>
                Suppliers
              </div>
            </div>
          </div>
        </li>
        <div className="contacts-search-wrapper search-by-supplier">
          <Search
            className="search-box"
            placeholder="Search by Supplier"
            defaultValue={searchTerm}
            onPressEnter={onSearch}
          />
        </div>
      </ul>
      <ReactResizeDetector handleHeight targetDomEl={this}>
        <div className="grid-panel">
          <Table
            pagination={false}
            columns={columns}
            dataSource={dashboard.data.suppliers}
            scroll={{ x: 'max-content', y: gridScrollHeight }}
          />
        </div>
      </ReactResizeDetector>

      <div className="contacts-dash-pagi-wrapper">
        <Select
          className="page-size-select"
          defaultValue={pageSize == dataCount ? 'All' : pageSize}
          onChange={onChangePageSize}
        >
          {PAGE_SIZES.map(obj => (
            <Option key={obj} value={obj}>
              {obj}
            </Option>
          ))}
        </Select>

        <div>/ Page</div>

        <Pagination current={page} pageSize={pageSize} total={dataCount} onChange={onPagination} />
      </div>
    </React.Fragment>
  ) : (
    <AppLoader show />
  );
};

export default memo(withTranslation()(withRouter(AssociateDashboard)));

AssociateDashboard.propTypes = {};
