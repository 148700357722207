import { action } from 'reduxHelpers';
import {
  CONTACTS,
  CREATECONTACT,
  DASHBOARD,
  UPDATECONTACT,
  USER,
  DOWNLOADCONTACTS,
  DELETECONTACTBYID,
  CHARTS,
  BROKERREQUESTS,
  ONBOARDBROKER
} from './actionTypes';

const fetchContacts = suvc => action(CONTACTS.REQUEST, { suvc });
const loadUserDetails = userId => action(USER.REQUEST, { userId });
const updateContact = (suvc, contactId, contact) => action(UPDATECONTACT.REQUEST, { suvc, contactId, contact });
const createContact = (suvc, contact) => action(CREATECONTACT.REQUEST, { suvc, contact });
const downloadContact = () => action(DOWNLOADCONTACTS.REQUEST);
const loadDashboardDetails = (page, pageSize, searchTerm) => action(DASHBOARD.REQUEST, { page, pageSize, searchTerm });
const deleteContact = (suvc, contactId) => action(DELETECONTACTBYID.REQUEST, { suvc, contactId });
const completeContactDownload = () => action(DOWNLOADCONTACTS.DOWNLOAD_COMPLETE);
const activateCharts = chartType => action(CHARTS.REQUEST, chartType);
const fetchBrokerRequests = data => action(BROKERREQUESTS.REQUEST, { data });
const onboardBroker = data => action(ONBOARDBROKER.REQUEST, { data });

export {
  fetchContacts,
  loadUserDetails,
  updateContact,
  createContact,
  loadDashboardDetails,
  downloadContact,
  deleteContact,
  completeContactDownload,
  activateCharts,
  fetchBrokerRequests,
  onboardBroker
};
