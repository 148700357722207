import { put } from 'redux-saga/effects';
import * as _ from 'lodash';

import { getRequest, putRequest, postRequest, deleteRequest } from '_http';
import { CONTACTS, CREATECONTACT, DELETECONTACTBYID, DOWNLOADCONTACTS, UPDATECONTACT } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

function* fetchContactsAsync({ suvc }) {
  try {
    const response = yield getRequest(`/contact/vendors/${suvc}`);
    yield put({ type: CONTACTS.SUCCESS, payload: _.get(response.data, 'contacts', []), rme_vendors: _.get(response.data, 'rme_vendors', [])});
  } catch (error) {
    yield put({ type: CONTACTS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CONTACTS LOADING ERROR'
      })
    );
  }
}

function* CreateContactsAsync({ suvc, contact }) {
  try {
    const response = yield postRequest(`/contact/vendors/${suvc}/contact`, contact);
    yield put({ type: CREATECONTACT.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATECONTACT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.response.data.description,
        className: 'error',
        message: error.response.data.message
      })
    );
  }
}

function* UpdateContactsAsync({ suvc, contactId, contact }) {
  try {
    let response = yield putRequest(`/contact/vendors/${suvc}/contact/${contactId}`, contact);
    yield put({ type: UPDATECONTACT.SUCCESS, payload: { contactId, contact: response.data } });
  } catch (error) {
    yield put({ type: UPDATECONTACT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CONTACTS UPDATE ERROR'
      })
    );
  }
}

function* DeleteContactsByIdAsync({ suvc, contactId }) {
  try {
    yield deleteRequest(`/contact/${suvc}/contact/${contactId}`);
    yield put({ type: DELETECONTACTBYID.SUCCESS, payload: { contactId } });
  } catch (error) {
    yield put({ type: DELETECONTACTBYID.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CONTACT DELETE ERROR'
      })
    );
  }
}

function* loadDashboardDownloadAsync() {
  try {
    const response = yield getRequest(`/dash/download`);
    yield put({ type: DOWNLOADCONTACTS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DOWNLOADCONTACTS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CONTACT DOWNLOAD ERROR'
      })
    );
  }
}

export {
  fetchContactsAsync,
  UpdateContactsAsync,
  CreateContactsAsync,
  loadDashboardDownloadAsync,
  DeleteContactsByIdAsync
};
