const downloadFile = (fileName, data, fileType) => {
  const file = new Blob([data], { type: 'text/plain' });
  if (window.navigator.msSaveOrOpenBlob) {
    // Required for this to work in IE
    const fullName = `${fileName}.${fileType}`;
    window.navigator.msSaveBlob(file, fullName);
  } else {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.${fileType}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
};
const FileDownload = (fileName, data) => {
  downloadFile(fileName, data, 'csv');
};
export default FileDownload;
