/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import moment from 'moment';
import { Table, Pagination, Icon } from 'antd';
import * as _ from 'lodash';
import { selectUserPemissions } from '../../selectors/UserSelector';
import {
  selectBrokerRequests,
  selectBrokerRequestsFetching,
  selectBrokerRequestPage,
  selectBrokerRequestPageSize,
  selectBrokerRequestCount,
  selectBrokerFilterOptions
} from '../../selectors/BrokerSelector';
import { fetchBrokerRequests } from '../../actions';
import ApproveButton from './ApproveButton';
import ExpandedContent from './ExpandedContent';
import TableSearch from './TableSearch';
import SwtichFilter from './SwitchFilter';
import { INITIAL_BROKER_REQUEST_PAGE_SIZE } from '../../lib/Constants';
import { getSortOrder } from '../../lib/Helpers';

const Brokers = () => {
  const dashboardPermissions = useSelector(selectUserPemissions);
  const pendingBrokerRequests = useSelector(selectBrokerRequests);
  const isBrokerRequestsListFetching = useSelector(selectBrokerRequestsFetching);
  const page = useSelector(selectBrokerRequestPage);
  const pageSize = useSelector(selectBrokerRequestPageSize);
  const allRequestCount = useSelector(selectBrokerRequestCount);
  const { searchFilters, orderBy } = useSelector(selectBrokerFilterOptions);
  const dispatch = useDispatch();

  const fetchAllBrokerRequests = useCallback(
    data => {
      dispatch(fetchBrokerRequests(data));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchAllBrokerRequests({ page, pageSize, orderBy, searchFilters });
  }, []);

  const handlePagination = pageNo => {
    fetchAllBrokerRequests({ pageNo, pageSize, orderBy, searchFilters });
  };

  const handleFilterChanges = (pagination, filters, sorter) => {
    const field = _.get(sorter, 'field', null);
    let order = _.get(sorter, 'order', false);
    if (order === 'ascend') {
      order = 'asc';
    } else if (order === 'descend') {
      order = 'desc';
    }
    const orderByValues = field ? { param: field, val: order } : null;
    fetchAllBrokerRequests({ page, pageSize, orderBy: orderByValues, searchData: searchFilters });
  };

  const getSearchFilterValue = field => _.find(searchFilters, obj => obj.param === field);

  const getTableFilterIconColor = field => {
    if (!_.isEmpty(getSearchFilterValue(field))) return '#108ee9';
    return '#aaa';
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 20,
      sorter: true,
      sortOrder: getSortOrder('id', orderBy),
      render: id => <span>{id}</span>
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
      width: 140,
      filterIcon: (
        <Icon
          type="search"
          style={{
            color: getTableFilterIconColor('full_name')
          }}
        />
      ),
      sorter: true,
      sortOrder: getSortOrder('full_name', orderBy),
      filterDropdown: (
        <TableSearch
          field="full_name"
          searchFilters={searchFilters}
          orderBy={orderBy}
          page={page}
          pageSize={pageSize}
          isBrokerRequestsListFetching={isBrokerRequestsListFetching}
        />
      ),
      render: full_name => <span>{full_name}</span>
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 140,
      filterIcon: (
        <Icon
          type="search"
          style={{
            color: getTableFilterIconColor('title')
          }}
        />
      ),
      sorter: true,
      sortOrder: getSortOrder('title', orderBy),
      filterDropdown: (
        <TableSearch
          field="title"
          searchFilters={searchFilters}
          orderBy={orderBy}
          page={page}
          pageSize={pageSize}
          isBrokerRequestsListFetching={isBrokerRequestsListFetching}
        />
      ),
      render: title => <span>{title}</span>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 120,
      filterIcon: (
        <Icon
          type="search"
          style={{
            color: getTableFilterIconColor('email')
          }}
        />
      ),
      sorter: true,
      sortOrder: getSortOrder('email', orderBy),
      filterDropdown: (
        <TableSearch
          field="email"
          searchFilters={searchFilters}
          orderBy={orderBy}
          page={page}
          pageSize={pageSize}
          isBrokerRequestsListFetching={isBrokerRequestsListFetching}
        />
      ),
      render: email => <span>{email}</span>
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      width: 110,
      filterIcon: (
        <Icon
          type="search"
          style={{
            color: getTableFilterIconColor('mobile_number')
          }}
        />
      ),
      sorter: true,
      sortOrder: getSortOrder('mobile_number', orderBy),
      filterDropdown: (
        <TableSearch
          field="mobile_number"
          searchFilters={searchFilters}
          orderBy={orderBy}
          page={page}
          pageSize={pageSize}
          isBrokerRequestsListFetching={isBrokerRequestsListFetching}
        />
      ),
      render: mobile_number => <span>{mobile_number}</span>
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
      sorter: true,
      sortOrder: getSortOrder('created_at', orderBy),
      render: createdAt => <span>{moment(createdAt).format('MM/DD/YYYY')}</span>
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: (status, record) => <ApproveButton status={status} record={record} permissions={dashboardPermissions} />
    }
  ];
  return (
    <React.Fragment>
      <SwtichFilter field="status" searchFilters={searchFilters} orderBy={orderBy} page={page} pageSize={pageSize} />
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="grid-panel grid-panel-broker feedback-grid-panel">
            <Table
              rowKey="id"
              className="broker-requests-table"
              columns={columns}
              dataSource={pendingBrokerRequests}
              size="small"
              loading={isBrokerRequestsListFetching}
              onChange={handleFilterChanges}
              expandedRowRender={record => {
                return <ExpandedContent record={record} />;
              }}
              expandRowByClick
              pagination={false}
            />
            <Pagination
              size="small"
              className="grid-pagination"
              onChange={pageNo => handlePagination(pageNo)}
              total={Number(allRequestCount)}
              showTotal={total => `${total} rows`}
              current={page}
              defaultPageSize={INITIAL_BROKER_REQUEST_PAGE_SIZE}
              hideOnSinglePage
              showQuickJumper
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default Brokers;
