import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { DASHBOARD } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

function* loadDashboardAsync({ page, pageSize, searchTerm }) {
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    page = 100;
  }

  try {
    let uri = '';

    if (searchTerm) {
      let tablefilter = [{ param: 'supplierName', val: [searchTerm], operator: 'like' }];
      uri = `&tablefilter=${encodeURIComponent(JSON.stringify(tablefilter))}`;
    }

    const response = yield getRequest(`/dash?page=${page}&pagesize=${pageSize}${uri}`);
    yield put({ type: DASHBOARD.SUCCESS, payload: { data: response.data, page, pageSize, searchTerm } });
  } catch (error) {
    yield put({ type: DASHBOARD.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'DASHBOARD LOADING ERROR'
      })
    );
  }
}

export { loadDashboardAsync };
