import initialState from 'store/initialState';
import { createReducer } from 'reduxHelpers';

import { DOWNLOADCONTACTS } from 'actions/actionTypes';

const dashboardDownload = (state = initialState.dashboardDownload, action) => {
  switch (action.type) {
    case DOWNLOADCONTACTS.REQUEST:
      return { ...state, fetching: true, error: null };
    case DOWNLOADCONTACTS.SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case DOWNLOADCONTACTS.DOWNLOAD_COMPLETE:
      return { ...state, fetching: false, data: { vendors: [] }, error: null };
    case DOWNLOADCONTACTS.FAILURE:
      return { ...state, fetching: false, data: [], error: action.error };
    default:
      return state;
  }
};

createReducer(DOWNLOADCONTACTS, initialState.dashboardDownload);

export default dashboardDownload;
