import { INITIAL_BROKER_REQUEST_PAGE_SIZE } from '../../lib/Constants';

const brokerRequests = {
  isFetchingBrokerRequests: false,
  requestsList: [],
  error: null,
  page: 1,
  pageSize: INITIAL_BROKER_REQUEST_PAGE_SIZE,
  searchTerm: null,
  brokerRequestCount: 0,
  filterOptions: { searchFilters: [], orderBy: null }
};

export default brokerRequests;
