/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectUserPemissions } from '../selectors/UserSelector';
import Brokers from '../components/brokers';

const Header = () => {
  const dashboardPermissions = useSelector(selectUserPemissions);
  return (
    <div className="grid-filter-panel broker-list-panel">
      <div
        className="back-to-dashboard"
        onClick={() => {
          window.location.href = !dashboardPermissions ? '/suite/dashboard' : '/suite/contact/admin/dashboard';
        }}
      />
      <div className="bread-crum-seperator"></div>
      <div className="bread-crum-caption">Contact Management</div>
      <div className="subtitle">
        The following is a list of broker requests within your organization. Please review and approve the requests in
        order to giving the access into the portal.
      </div>
    </div>
  );
};

const BrokerRequests = () => {
  return (
    <React.Fragment>
      <Header />
      <Brokers />
    </React.Fragment>
  );
};

export default withRouter(BrokerRequests);
