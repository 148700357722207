import React from 'react';
import { Chart } from 'react-google-charts';
import * as _ from 'lodash';

class ContactCharts extends React.Component {
  getAllRoles = () => {
    let allRoles = [];

    _.forEach(this.props.contacts, obj => {
      allRoles = [...allRoles, ...obj.roles];
    });

    return allRoles;
  };

  getContactCount = () => {
    let primaryCount = 0;
    let secondaryCount = 0;
    let masterDataCount = 0;
    let allRoles = this.getAllRoles();

    _.forEach(allRoles, role => {
      if (role === 1) primaryCount++;
      if (role === 2) secondaryCount++;
      if (role === 4) masterDataCount++;
    });

    return { primaryCount, secondaryCount, masterDataCount };
  };

  getContactStatus = type => {
    let status = 'missing';

    let allRoles = this.getAllRoles();
    if (_.includes(allRoles, type)) status = 'exist';

    return status;
  };

  render() {
    let { contacts, contactScore } = this.props;
    let { primaryCount, secondaryCount, masterDataCount } = this.getContactCount();
    let colors = [];

    // const getContactStatus = type => {
    //   let exsits = 'missing';
    //   let roleId = 0;
    //   switch (type) {
    //     case 'primary':
    //       roleId = 1;
    //       break;
    //     case 'secondary':
    //       roleId = 2;
    //       break;
    //     case 'master':
    //       roleId = 4;
    //       break;
    //   }
    //   if (contacts.corporate) {
    //     if (contacts.corporate[type].contactId) {
    //       if (contacts.corporate[type].status !== 'OUTDATED') {
    //         exsits = 'exist';
    //       } else {
    //         exsits = 'outdated';
    //       }
    //     }
    //     if (contacts.corporate[type].status === 'MAPPED') {
    //       ['primary', 'secondary', 'master'].forEach(coporateContactType => {
    //         if (
    //           contacts.corporate[coporateContactType].contactId &&
    //           contacts.corporate[coporateContactType].additionalRoles &&
    //           contacts.corporate[coporateContactType].additionalRoles.length > 0
    //         ) {
    //           contacts.corporate[coporateContactType].additionalRoles.forEach(linkedExistingRole => {
    //             if (linkedExistingRole.id === roleId) {
    //               if (contacts.corporate[coporateContactType].status !== 'OUTDATED') {
    //                 exsits = 'exist';
    //               } else {
    //                 exsits = 'outdated';
    //               }
    //             }
    //           });
    //         }
    //       });
    //     }
    //   }
    //   return exsits;
    // };

    const getContactColor = type => {
      let contactStatus = this.getContactStatus(type);
      switch (contactStatus) {
        case 'exist':
          return '#92bb88';
        case 'outdated':
          return '#f1c40f';
        default:
          return '#e9705f';
      }
    };

    if (contacts) {
      colors = [getContactColor(1), getContactColor(2), getContactColor(4)];
    }

    let syscoContacts = [
      {
        title: 'Primary Sysco Contact',
        name: 'Todd Green',
        role: 'Sr. Director Strategic Sourcing',
        email: 'green.todd@corp.sysco.com',
        phone: '(281) 584-1238'
      },
      {
        title: 'Category Assortment',
        name: 'Tom Matthews',
        role: 'Manager Category Management',
        email: 'matthews.to@corp.sysco.com',
        phone: '(281) 584-1726'
      },
      {
        title: 'Sourcing Manager',
        name: 'Patrick Wei',
        role: 'Manager Strategic Sourcing',
        email: 'wei.patrick@corp.sysco.com',
        phone: '(281) 584-4064'
      },
      {
        title: 'One Sysco Data',
        name: 'Ana Martin',
        role: 'Sr. Manager Supplier Managment',
        email: 'osdsupplier@corp.sysco.com',
        phone: '(281) 436-7616'
      }
    ];

    return (
      <div className="attribution-charts-wrapper">
        <div className="overall-donut-chart-outer-wrapper">
          <div className="overall-donut-chart-wrapper" style={{ width: '200px' }}>
            <div className={'donut-title'}>CONTACT COMPLIANCE</div>
            <div className="percentage-completion">{contactScore + '%'}</div>
            <div className={'overall-donut-chart'}>
              <Chart
                chartType="PieChart"
                data={[['Type', 'User'], ['Primary', 1], ['Secondary', 1], ['Master', 1]]}
                width="270px"
                height="230px"
                legendToggle
                options={{
                  title: '',
                  pieHole: 0.62,
                  colors: colors,
                  legend: 'none',
                  pieSliceText: 'none',
                  backgroundColor: '#f9f6f8',
                  tooltip: {
                    trigger: 'none'
                  }
                }}
              />
            </div>
            <div className={'attribute-type-chart-title'} style={{ position: 'unset', margin: '5px 0' }}>
              REQUIRED CONTACTS
            </div>
            <div className="donut-chart-legend">
              <div className="dot green"></div>
              <div className="description">Available</div>
              <div className="dot red"></div>
              <div className="description">Missing</div>
            </div>
          </div>
          <ul className="overall-donut-chart-summary">
            <li>PRIMARY CONTACT: {primaryCount}</li>
            <li>SECONDARY CONTACT: {secondaryCount}</li>
            <li>MASTER CONTACT: {masterDataCount}</li>
          </ul>
        </div>
        <div className="sysco-cards" style={{ display: 'none' }}>
          <div className="title">SYSCO’S POINTS OF CONTACT</div>
          <div className="sysco-cards-body">
            {syscoContacts.map((syscoContact, index) => (
              <div key={index} className="sysco-card">
                <div className="card-title">{syscoContact.title}</div>
                <div className="card-name">{syscoContact.name}</div>
                <div className="card-details">
                  <div className="left">
                    <div className="detail">
                      <div className="detail-tag">Role</div>
                      <div className="detail-value">{syscoContact.role}</div>
                    </div>
                    <div className="detail">
                      <div className="detail-tag">Email</div>
                      <div className="detail-value">{syscoContact.email}</div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="detail">
                      <div className="detail-tag">Phone</div>
                      <div className="detail-value">{syscoContact.phone}</div>
                    </div>
                    <div className="send-email">
                      <a href={`mailto:${syscoContact.email}`}>
                        <svg
                          enableBackground="new 0 0 24 24"
                          height="15"
                          viewBox="0 0 24 24"
                          width="15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z" />
                          <path d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactCharts;
