import React, { useCallback, useState, useEffect } from 'react';
import { Button, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { fetchBrokerRequests } from '../../actions';

const SwtichFilter = ({ searchFilters, field, pageSize, orderBy, isBrokerRequestsListFetching }) => {
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    setSearchData(searchFilters);
  }, [searchFilters]);

  const fetchAllBrokerRequests = useCallback(
    data => {
      dispatch(fetchBrokerRequests(data));
    },
    [dispatch]
  );

  const statusObject = _.find(searchData, ({ param }) => field === param);
  const searchFieldValue = _.get(statusObject, 'val[0]', '');

  const onTableSearchInputChange = (value, columnName = 'status') => {
    const clone = [...searchData];
    const target = _.find(clone, obj => obj.param === columnName);

    if (target) {
      target.val = [value];
    } else {
      clone.push({ param: columnName, val: [value], operator: '=' });
    }
    setSearchData(clone);
    fetchAllBrokerRequests({ pageSize, page: 1, orderBy, searchData: clone });
  };

  const onResetAllFilters = () => {
    fetchAllBrokerRequests({ pageSize, page: 1 });
  };

  const onTableSearchClear = checkedStatus => {
    onTableSearchInputChange(Number(checkedStatus));
  };

  return (
    <div className="onboard-switch-filter">
      <Button className="clear-all-filters" onClick={() => onResetAllFilters()}>
        Clear Filters
      </Button>
      <span className="link-seperator">|</span>
      <span title="This will filter all the onboarding requests with the status" className="switch-title-span">
        Already Onboarded
      </span>
      <Switch
        disabled={isBrokerRequestsListFetching}
        checked={!!searchFieldValue}
        onChange={onTableSearchClear}
        title="This will filter all the onboarding requests with the status"
      />
    </div>
  );
};

export default SwtichFilter;
