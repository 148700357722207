import { takeLatest } from 'redux-saga/effects';
import { BROKERREQUESTS, ONBOARDBROKER } from 'actions/actionTypes';
import { loadBrokerRequestsAsync, onboardBrokerAsync } from './BrokerSaga';

const brokerSaga = [
  takeLatest(BROKERREQUESTS.REQUEST, loadBrokerRequestsAsync),
  takeLatest(ONBOARDBROKER.REQUEST, onboardBrokerAsync)
];

export default brokerSaga;
