import { createRequestTypes, createRequestTypesU } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const ROLES = createRequestTypes('ROLES');
const CONTACTS = createRequestTypes('CONTACTS');
const UPDATECONTACT = createRequestTypes('UPDATECONTACT');
const CREATECONTACT = createRequestTypes('CREATECONTACT');
const DOWNLOADCONTACTS = createRequestTypesU('DOWNLOADCONTACTS');
const DELETECONTACTBYID = createRequestTypes('DELETECONTACTBYID');
const CHARTS = createRequestTypes('CHARTS');
const BROKERREQUESTS = createRequestTypes('BROKERREQUESTS');
const ONBOARDBROKER = createRequestTypes('ONBOARDBROKER');

const DASHBOARD = createRequestTypes('DASHBOARD');

export {
  USER,
  ROLES,
  CONTACTS,
  UPDATECONTACT,
  CREATECONTACT,
  DASHBOARD,
  DOWNLOADCONTACTS,
  DELETECONTACTBYID,
  CHARTS,
  BROKERREQUESTS,
  ONBOARDBROKER
};
