import user from './InitialState/userInitialState';
import contacts from './InitialState/contactsInitialState';
import dashboard from './InitialState/dashboardInitialState';
import dashboardDownload from './InitialState/dashboardDownloadInitialState';
import brokerRequests from './InitialState/brokerInitialState';
import roles from './InitialState/roleInitialState';

const initialState = {
  user,
  contacts,
  dashboard,
  dashboardDownload,
  brokerRequests,
  roles
};
export default initialState;
