import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { USER, ROLES } from 'actions/actionTypes';
import * as _ from 'lodash';

import { includes, split } from 'lodash';
import { action } from 'reduxHelpers';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getUserId = username => {
  if (includes(username, adPrefix)) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  var oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

function* loadUserAsync({ userId }) {
  try {

    const response2 = yield getRequest(`/contact/roles/all`);
    yield put({ type: ROLES.SUCCESS, payload: response2.data });

    const getModifiableRolesOverride = (user, allRoles) => {
      let roles = _.get(user, 'roles', []);
      let roleObjs = _.filter(allRoles, role => {
        return roles.includes(role.id) && role.cmModifiableRolesOverride;
      });
    
      let modifiableRolesOverrides = [];
      roleObjs.forEach(roleObj => {
        modifiableRolesOverrides = [
          ...modifiableRolesOverrides,
          ...roleObj.cmModifiableRolesOverride.split(',').map(x => parseInt(x))
        ];
      });
    
      return modifiableRolesOverrides;
    };

    const response = yield getRequest(`/user/${getUserId(userId)}?app_name=CNTAPP`);
    yield put({ type: USER.SUCCESS, payload: {...response.data, modifiableRolesOverride: getModifiableRolesOverride(response.data, response2.data)}});

  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER LOADING ERROR'
      })
    );
  }
}

export { loadUserAsync };
