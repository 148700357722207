import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { onboardBroker } from '../../actions';

const getOnboardingButtonTitle = ({ isUpdating }) => (isUpdating ? 'ONBOARDING...' : 'APPROVE');

const checkApprovalPermissions = permissions => !permissions.includes('CNTAPP.BROKERONBOARD');

const getApproveButtonDisabledStatus = (isUpdating, permissions) => isUpdating || checkApprovalPermissions(permissions);

const getApproveButtonTitle = (isUpdating, permissions) => {
  if (isUpdating) return 'Processing...';
  if (checkApprovalPermissions(permissions)) return `You don't have enough permissions to perform this action`;
  return 'This will onboard the broker';
};

const ApproveButton = ({ status, record, permissions }) => {
  const dispatch = useDispatch();

  const makeOnboardRequest = useCallback(() => {
    dispatch(onboardBroker(record));
  }, [dispatch]);

  if (status === 1) {
    return (
      <div className="completed-tag" title="This has been approved.">
        APPROVED
      </div>
    );
  }
  return (
    <Button
      type="primary"
      className="btn in-grid title-bar-button green-action-btn"
      onClick={e => {
        e.stopPropagation();
        makeOnboardRequest();
      }}
      title={getApproveButtonTitle(record.isUpdating, permissions)}
      disabled={getApproveButtonDisabledStatus(record.isUpdating, permissions)}
    >
      {getOnboardingButtonTitle(record)}
    </Button>
  );
};

export default ApproveButton;
